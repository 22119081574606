'use client';

import { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import ChevronDown from '@assets/images/ChevronDown.svg';
import ChevronUp from '@assets/images/ChevronUp.svg';
import Minus from '@assets/images/Minus.svg';
import Plus from '@assets/images/Plus.svg';
import { onEnterSpacePress } from '@utils/keyboard';

/**
 * @class
 * @param {Object} classNames classnames for elements
 * @param {string} header Specifies header of accordion
 * @param {Object} content Content of the Accordion
 * @param {Boolean} disabled prop to define enablity of Accordion
 *
 * @returns Button Component as per the variant
 */
const Accordion = ({
  classNames = {},
  header,
  accordionId,
  isOpen,
  onClick,
  disabled = false,
  plusMinusIcons = false,
  children,
  upDownIcons = false,
  expandedIcon,
  collapseIcon
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const isUpdownIcons = upDownIcons && {
    expandedIcon: ChevronDown.src,
    collapseIcon: ChevronUp.src
  };

  const isPlusminusIcons = !isUpdownIcons &&
    plusMinusIcons && {
      expandedIcon: Plus.src,
      collapseIcon: Minus.src
    };

  const imageSrc = isUpdownIcons ||
    isPlusminusIcons || { expandedIcon, collapseIcon };

  const handleAccordionClick = () => {
    setOpen(!open);
    onClick?.((open && isOpen) || isOpen ? null : accordionId);
  };
  return (
    <div
      className={cx(
        'mx-5',
        {
          'pointer-events-none bg-[#FAFAFA]': disabled
        },
        classNames?.container
      )}
    >
      <summary
        className={cx(
          'flex flex-wrap items-center flex-row list-none cursor-pointer display-none  [&::-webkit-details-marker]:hidden w-full',
          classNames?.parent
        )}
        data-testid='Accordion'
        onKeyDown={onEnterSpacePress(handleAccordionClick)}
        onClick={handleAccordionClick}
        role='button'
        tabIndex={0}
      >
        <div
          className={cx(
            'flex flex-1 font-semibold justify-between items-center mb-0',
            {
              'text-faint': disabled,
              'text-greish': !disabled
            },
            classNames?.header
          )}
        >
          {header}
          <span
            style={{
              background: `url('${
                open ? imageSrc.collapseIcon : imageSrc.expandedIcon
              }') no-repeat center center`
            }}
            className='h-10 w-10 my-5'
          />
        </div>
      </summary>
      <div
        className={cx(
          'custom-scrollbar p-3 overflow-x-hidden overflow-y-auto animate__animated animate__fadeIn',
          `${open ? 'flex flex-col pt-3' : 'hidden'}`,
          classNames?.content
        )}
        data-testid='Accordion-content'
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
