import React, { memo, useState } from 'react';
import Link from 'next/link';
import cxv from 'classnames';
import Accordion from '@components/accordion';
import Button from '@components/button';
import RichText from '@components/richtext';
import { getOpenerType } from '@utils/common';
import { getMonthName } from '@constants/dashboards';

const TabPanel = ({ children, selected }) => {
  return selected ? (
    <div key={selected ? selected : 'empty'}>{children}</div>
  ) : null;
};

const Tab = ({ children, onClick, selected }) => {
  return (
    <div
      className={cxv(
        'p-4',
        'text-base',
        'cursor-pointer',
        'border-b-4',
        'border-solid',
        'border-t-0',
        'border-l-0',
        'border-r-0',
        '-mb-[1px]',
        'font-bold',
        'md:text-xl',
        {
          'border-primary text-primary': selected
        },
        {
          'border-transparent text-faint': !selected
        }
      )}
      onKeyDown={e => e.key === 'Enter' && onClick(e)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const ReactTabs = ({ tabPanels, tabNames = [], onLoadMore }) => {
  const [selectedTab, selectTab] = useState(0);

  const handleViewMore = index => {
    onLoadMore(index);
  };

  return (
    <div>
      {/* Tabs starts */}
      <div className='flex flex-nowrap overflow-x-auto overflow-y-hidden whitespace-nowrap mb-14 border-b-[1px] border-faint border border-solid border-t-0 border-l-0 border-r-0 bg-[#FAFAFA] sm:justify-center'>
        {tabNames?.map((tabName, index) => (
          <Tab
            key={tabName}
            selected={selectedTab === index}
            onClick={() => selectTab(index)}
          >
            {tabName}
          </Tab>
        ))}
      </div>
      {/* Tabs ends */}
      {/* TabPanels starts */}
      <div className='container mx-auto max-w-5xl'>
        {tabPanels.map((tabPanel, index) => (
          <TabPanel key={tabPanel.key} selected={selectedTab === index}>
            {tabPanel.accordion?.map((accordionItem, iIndex) => {
              const details = accordionItem?.fields?.accordionContent?.map(
                (content, i) => (
                  <div
                    key={i}
                    className='py-6 pt-0 mb-5 border-b-[1px] border-faint border-t-0 border-r-0 border-l-0 border-solid last:border-b-0'
                  >
                    <h3 className='text-primary font-light text-xl mt-0'>
                      {content?.fields?.headlineSubText?.fields?.heading}
                    </h3>
                    <div className='text-greish text-sm'>
                      {content?.fields?.headlineSubText?.fields?.subText ? (
                        <RichText
                          text={content.fields.headlineSubText.fields.subText}
                          classNames={{ paragraphCustomClasses: 'my-[14px]' }}
                        />
                      ) : null}
                    </div>
                    {content.fields.cta?.fields?.url ? (
                      <Link
                        href={content.fields.cta.fields?.url}
                        passHref={true}
                      >
                        <a
                          className='text-bold underline'
                          target={getOpenerType(
                            content?.fields?.cta?.fields?.openerType
                          )}
                          rel='noopener noreferrer'
                        >
                          {content?.fields?.cta?.fields?.label}
                        </a>
                      </Link>
                    ) : null}
                    {content.fields.cta?.fields?.mediaUrl?.fields?.file?.url ? (
                      <a
                        href={`https:${content.fields.cta.fields.mediaUrl.fields.file.url}`}
                        className='text-bold underline'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {content?.fields?.cta?.fields?.label}
                      </a>
                    ) : null}
                  </div>
                )
              );
              const content = (
                <div>
                  <div className='mt-0'>{details?.map(detail => detail)}</div>
                </div>
              );

              const actualDate = new Date(accordionItem?.fields?.date);
              const displayDate =
                getMonthName(actualDate.getMonth()) +
                ' ' +
                actualDate.getDate() +
                ', ' +
                actualDate.getFullYear();

              return (
                <Accordion
                  classNames={{
                    container:
                      'border-b-[1px] border-faint border-t-0 border-r-0 border-l-0 border-solid last-of-type:mb-10',
                    content: '!p-0'
                  }}
                  expandedIcon={
                    accordionItem?.fields?.expandIcon?.fields?.file?.url
                  }
                  collapseIcon={
                    accordionItem?.fields?.collapseIcon?.fields?.file?.url
                  }
                  key={accordionItem.key}
                  header={
                    <div>
                      {accordionItem?.fields?.date && (
                        <h4 className='uppercase text-faint text-sm'>
                          {displayDate}
                        </h4>
                      )}
                      <p className='text-3xl font-thin my-[30px]'>
                        {accordionItem?.fields?.title}
                      </p>
                    </div>
                  }
                >
                  {content}
                </Accordion>
              );
            })}
            {/* display view more button */}
            {tabPanel.accordion.length > 0 && tabPanel.showViewMore ? (
              <div className='float-center text-center w-full'>
                <Button
                  className=' bg-[#4171df] text-sm text-white rounded-none border-none mt-10 mb-10'
                  onClick={() => handleViewMore(index)}
                >
                  View More
                </Button>
              </div>
            ) : null}
            {/* end view more button */}
            {!tabPanel.accordion.length && !tabPanel.showViewMore ? (
              <div className='float-center text-center w-full'>
                <h4 className='text-faint text-sm'>News not available</h4>
              </div>
            ) : null}
          </TabPanel>
        ))}
      </div>
      {/* TabPanels ends */}
    </div>
  );
};

export default memo(ReactTabs);
