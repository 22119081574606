import { memo } from 'react';
import cx from 'classnames';
import Cta from '@components/cta';
import RichText from '@components/richtext';
import { getBgColorClasses, getTextColorClasses } from '@utils/common';
import { alignmentClasses } from '@utils/common';
import { headingTagMap } from '@constants/index';

/**
 * @class
 * @param {Object} sectionData Fields Data for section elements
 *
 * @returns Section Component as per the section data
 */

const HeadingWithSubText = ({
  fields,
  classes,
  customCta,
  hideCTAOnMobile = false,
  contentWidth = ''
}) => {
  const {
    heading,
    subText,
    cta,
    headingSize,
    contentCopyAlignment,
    contentPosition,
    headingTextColor = 'Black',
    subTextColor = 'Black',
    backgroundColor = 'Transparent',
    styledHeading
  } = fields;

  const sectionClasses = cx(
    'flex',
    'flex-1',
    'align-middle',
    'border-b-0',
    'py-6',
    'px-10',
    'm-auto',
    'rich-text',
    getBgColorClasses(backgroundColor),
    alignmentClasses(contentPosition),
    classes?.sectionClasses
  );

  const {
    component: HeadingTag,
    componentStyles,
    subTextStyles
  } = headingTagMap[headingSize || 'H1'];

  return (
    <section className={sectionClasses} data-testid='headingWithSubText'>
      <div
        className={cx(
          'flex w-full items-center',
          alignmentClasses(contentPosition),
          classes?.containerClasses
        )}
      >
        <div className={cx(contentWidth)}>
          <HeadingTag
            className={cx(
              'mb-1 font-light m-0',
              componentStyles,
              getTextColorClasses(headingTextColor),
              alignmentClasses(contentCopyAlignment),
              classes?.headingClasses
            )}
          >
            {heading ? (
              heading
            ) : styledHeading ? (
              <RichText
                text={styledHeading}
                classNames={{ paragraphCustomClasses: '!mb-0' }}
              />
            ) : null}
          </HeadingTag>
          <div
            className={cx(
              'font-proLight',
              getTextColorClasses(subTextColor),
              subTextStyles,
              alignmentClasses(contentCopyAlignment)
            )}
          >
            {subText && (
              <>
                <RichText
                  text={subText}
                  urlTarget='New Tab'
                  holderClasses={classes?.holderClasses}
                  classNames={{
                    paragraphCustomClasses: classes?.paragraphCustomClasses
                  }}
                />
              </>
            )}
            <div
              className={cx(
                'flex space-x-2',
                alignmentClasses(contentCopyAlignment)
              )}
            >
              {cta && (
                <Cta
                  ctaArray={Array.isArray(cta) ? cta : [cta]}
                  tabIndex={-1}
                  classes={{
                    holderClasses: cx(
                      classes?.ctaHolderClasses,
                      {
                        'md:flex hidden': hideCTAOnMobile
                      },
                      {
                        'mt-5': !subText
                      }
                    )
                  }}
                />
              )}
              {customCta && (
                <Cta
                  ctaArray={Array.isArray(customCta) ? customCta : [customCta]}
                  tabIndex={-1}
                  classes={{
                    holderClasses: cx(
                      classes?.ctaHolderClasses,
                      'sm:flex hidden',
                      {
                        'mt-5': !subText
                      }
                    )
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(HeadingWithSubText);
